<template>
    <b-modal
        id="modal-change-password"
        title="Cambio de contraseña"
        header-bg-variant="primary"
        hide-footer
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <b-card class="p-3">
            <b-form>
                <b-row>
                    <b-col cols="12">
                        <div>
                            Para cambiar su contraseña por favor escriba su
                            contraseña actual y valídela, si es correcta proceda
                            a crear su nueva contraseña y luego confirmela.
                            Finalmente guarde su nueva contraseña y luego guarde
                            los cambios de su perfil
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col cols="12">
                        <b-form-group>
                            <label>Contraseña actual</label>
                            <b-form-input
                                v-model="$v.password.current.$model"
                                type="text"
                                :state="validateState('current')"
                                :disabled="matchPassword"
                            >
                            </b-form-input>
                            <div
                                class="length-field mt-1"
                                v-if="!$v.password.current.$anyError"
                            >
                                ({{ $v.password.current.$model.length }}
                                /
                                {{ $v.password.current.$params.maxLength.max }})
                            </div>
                            <b-form-invalid-feedback
                                v-if="!$v.password.current.maxLength"
                            >
                                Este campo debe tener máximo
                                {{ $v.password.current.$params.maxLength.max }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.current.minLength"
                            >
                                La contraseña debe tener al menos
                                {{ $v.password.current.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.current.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button
                            variant="primary"
                            class="my-4"
                            block
                            @click="verifyPassword"
                            :disabled="matchPassword || isLoading"
                        >
                            <span v-if="isLoading">Cargando...</span>
                            <span v-else>Verificar</span>
                        </b-button>
                    </b-col>
                    <b-col cols="12" v-if="matchPassword">
                        <b-form-group>
                            <label>Nueva contraseña</label>
                            {{ $v.password.newPwd.isEqual }}
                            <b-form-input
                                v-model="$v.password.newPwd.$model"
                                type="text"
                                :state="validateState('newPwd')"
                            >
                            </b-form-input>
                            <div
                                class="length-field mt-1"
                                v-if="!$v.password.newPwd.$anyError"
                            >
                                ({{ $v.password.newPwd.$model.length }}
                                /
                                {{ $v.password.newPwd.$params.maxLength.max }})
                            </div>
                            <b-form-invalid-feedback
                                v-if="!$v.password.newPwd.maxLength"
                            >
                                Este campo debe tener máximo
                                {{ $v.password.newPwd.$params.maxLength.max }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.newPwd.minLength"
                            >
                                La contraseña debe tener al menos
                                {{ $v.password.newPwd.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.newPwd.equalToCurrent"
                            >
                                La nueva contraseña debe ser distinta a la
                                actual
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.newPwd.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" v-if="matchPassword">
                        <b-form-group>
                            <label>Confirmar nueva contraseña</label>
                            <b-form-input
                                v-model="$v.password.confirmNew.$model"
                                type="text"
                                :state="validateState('confirmNew')"
                            >
                            </b-form-input>
                            <div
                                class="length-field mt-1"
                                v-if="!$v.password.confirmNew.$anyError"
                            >
                                ({{ $v.password.confirmNew.$model.length }}
                                /
                                {{
                                    $v.password.confirmNew.$params.maxLength
                                        .max
                                }})
                            </div>
                            <b-form-invalid-feedback
                                v-if="!$v.password.confirmNew.maxLength"
                            >
                                Este campo debe tener máximo
                                {{
                                    $v.password.confirmNew.$params.maxLength.max
                                }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.confirmNew.minLength"
                            >
                                La contraseña debe tener al menos
                                {{
                                    $v.password.confirmNew.$params.minLength.min
                                }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.confirmNew.equalToNew"
                            >
                                Las contraseñas no coinciden
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.password.confirmNew.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="float-right d-flex">
                    <b-button
                        variant="secondary"
                        class="mt-4 mr-4"
                        @click="close"
                        >Cancelar</b-button
                    >
                    <b-button
                        variant="primary"
                        size="md"
                        class="d-flex align-items-center mt-4"
                        @click="save"
                        ><feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />Guardar</b-button
                    >
                </div>
            </b-form>
        </b-card>
    </b-modal>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import services from '@/boot/axios'

const { API } = services

const defaultPassword = () =>
    JSON.parse(
        JSON.stringify({
            current: '',
            newPwd: '',
            confirmNew: ''
        })
    )

export default {
    name: 'ChangePassword',
    mixins: [validationMixin],
    data: () => ({
        password: defaultPassword(),
        matchPassword: false,
        isLoading: false
    }),
    validations: {
        password: {
            current: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50)
            },
            newPwd: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50),
                equalToCurrent: function () {
                    const { newPwd, current } = this.password
                    return !(newPwd === current)
                }
            },
            confirmNew: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50),
                equalToNew: function () {
                    const { confirmNew, newPwd } = this.password
                    return confirmNew === newPwd
                }
            }
        }
    },
    methods: {
        close() {
            this.$v.password.$model = defaultPassword()
            this.$v.password.$reset()
            this.matchPassword = false
            // setTimeout(() => {
            // }, 100)
            this.$bvModal.hide('modal-change-password')
        },
        verifyPassword() {
            const valid = !this.$v.password.current.$invalid
            if (valid) {
                this.isLoading = true
                API.POST({
                    url: 'user/query',
                    data: {
                        p_datajson: {
                            id: this.getUserData.id,
                            password: this.password.current
                        },
                        p_opc: 'VERIFY_PASSWORD',
                        p_auditoriajson: { usuario_id: this.getUserData.id }
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato: { verified },
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.matchPassword = verified
                            this.isLoading = false
                            if (!verified) {
                                this.$bvModal
                                    .msgBoxOk(
                                        'La contraseña ingresada no coincide con la contraseña actual',
                                        {
                                            title: 'Advertencia!',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'warning',
                                            okTitle: 'Aceptar',
                                            cancelTitle: 'Cancelar',
                                            footerClass: 'p-2',
                                            hideHeaderClose: false,
                                            centered: true,
                                            headerBgVariant: 'warning',
                                            headerClass: 'modal-header'
                                        }
                                    )
                                    .then(() => {
                                        // console.log(value);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
                // this.matchPassword = true
            } else {
                this.$v.password.current.$touch()
            }
        },
        save() {
            const valid = !this.$v.password.$invalid
            if (valid) {
                this.$emit('onChangePassword', this.password)
                this.close()
            } else {
                this.$v.password.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.password[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        ...mapState(['Theme']),
        ...mapGetters('usuario', ['getUserData'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
